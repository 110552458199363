*,
*::before,
*::after {
  box-sizing: border-box;
}

@font-face {
  font-family: "Gilroy";
  font-weight: 400;
  src: url(../assets/fonts/Gilroy-Regular.ttf);
}
@font-face {
  font-family: "Gilroy";
  font-weight: 500;
  src: url(../assets/fonts/Gilroy-Medium.ttf);
}
@font-face {
  font-family: "Gilroy";
  font-weight: 600;
  src: url(../assets/fonts/Gilroy-SemiBold.ttf);
}
@font-face {
  font-family: "Gilroy";
  font-weight: 700;
  src: url(../assets/fonts/Gilroy-Bold.ttf);
}
.text {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 5px;
}
.text_sm {
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 5px;
}
.text_md {
  font-size: 24px;
  line-height: 28px;
}
.text_lg {
  font-size: 32px;
  line-height: 39px;
  font-weight: 700;
}
.text_xl {
  font-size: 36px;
  line-height: 44px;
  font-weight: 700;
}
.text-orange {
  color: #FFD361;
}
.text-danger {
  color: #FF3737;
}
.text-white {
  color: #fff;
}

.btn {
  border: none;
  letter-spacing: -0.02em;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.2s ease;
  transition-property: background-color, color;
}
.btn:focus {
  outline: none;
}
.btn_lg {
  min-width: 320px;
  padding: 30px;
  font: 600 28px/33px "Gilroy", sans-serif;
}
.btn-white {
  background-color: #fff;
}
.btn-white:hover {
  background-color: #f0f0f0;
}
.btn-outline {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 25px 30px 25px 25px;
  background-color: #3F3B7B;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 15px;
  font: 600 20px/24px "Gilroy", sans-serif;
}
.btn-outline:hover {
  border-color: transparent;
  background-color: #fff;
  color: #000;
}
.btn-wide {
  min-width: 240px;
}
.btn-hover-img-invert img {
  transition: 0.2s ease filter;
}
.btn-hover-img-invert:hover img {
  filter: invert(1);
}
.btn-payment {
  background: #3F3B7B;
  color: #fff;
  border-radius: 15px;
  padding: 20px 50px 20px 20px;
  display: flex;
  gap: 20px;
  align-content: center;
  font: 600 20px/24px "Gilroy", sans-serif;
}
.btn-payment:hover {
  background: #46418d;
}
.btn-payment.active {
  background: #fff;
  color: #3F3B7B;
}
.btn-payment span .btn-payment__desc {
  display: block;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  opacity: 0.5;
  margin-top: 2px;
}
.btn-payment-success {
  background: rgba(116, 205, 93, 0.8);
  color: #fff;
  border-radius: 10px;
  padding: 15px 40px;
  font: 600 20px/24px "Gilroy", sans-serif;
}
.btn-payment-success:hover {
  background: rgb(116, 205, 93);
}
.btn-payment-success:disabled {
  background-color: #1d2129;
  color: rgba(255, 255, 255, 0.3);
  cursor: default;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.32);
}
.btn-payment-success:disabled:hover {
  background-color: #1d2129;
}

.card {
  min-width: 340px;
  padding-top: 20px;
}
.card-column {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.card-small {
  min-width: 180px;
}
.input {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 18px 18px;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  border: 2px solid transparent;
}
@media (max-width: 575px) {
  .input {
    padding: 15px;
  }
}
.input-danger {
  border-color: #FF3737;
}
.input-light {
  background: #3F3B7B;
  border-radius: 15px;
  font-size: 20px;
  line-height: 24px;
}
.input label {
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  letter-spacing: -0.02em;
  color: rgba(255, 255, 255, 0.5);
}
.input input {
  border: none;
  background: none;
  color: #fff;
  font: inherit;
  font-size: 20px;
  font-family: monospace;
  outline: none;
  margin-right: 30px;
  width: calc(100% - 50px);
}
.input .copy {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='25' viewBox='0 0 22 25' fill='none' %3E%0A%3Cpath d='M3.37852 24.157C2.71852 24.157 2.15332 23.9218 1.68292 23.4514C1.21252 22.981 0.977718 22.4162 0.978518 21.757V4.95698H3.37852V21.757H16.5785V24.157H3.37852ZM8.17852 19.357C7.51852 19.357 6.95332 19.1218 6.48292 18.6514C6.01252 18.181 5.77772 17.6162 5.77852 16.957V2.55698C5.77852 1.89698 6.01372 1.33178 6.48412 0.861384C6.95452 0.390984 7.51932 0.156184 8.17852 0.156984H18.9785C19.6385 0.156984 20.2037 0.392184 20.6741 0.862584C21.1445 1.33298 21.3793 1.89778 21.3785 2.55698V16.957C21.3785 17.617 21.1433 18.1822 20.6729 18.6526C20.2025 19.123 19.6377 19.3578 18.9785 19.357H8.17852Z' fill='white'/%3E%0A%3C/svg%3E%0A");
  cursor: pointer;
  position: absolute;
  right: 20px;
  width: 23px;
  height: 24px;
  background-repeat: no-repeat;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-between {
  justify-content: space-between;
}

.m-t-25 {
  margin-top: 25px;
}

.m-y-50 {
  margin: 50px 0;
}

@media (max-width: 768px) {
  .m-y-md-30 {
    margin: 30px 0;
  }
}

.gap-40 {
  gap: 40px;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
  gap: 20px;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .row {
    flex-direction: column;
  }
}

.flex-2 {
  flex: 2;
}

.flex-4 {
  flex: 4;
}

.op-5 {
  opacity: 0.5;
}

.text-center {
  text-align: center;
}

.payment-system {
  max-width: 820px;
  margin: 100px auto;
  position: relative;
}
@media (max-width: 768px) {
  .payment-system {
    margin: 30px auto;
  }
}
.payment-system .wrapper {
  /* padding: 15px 40px 25px; */
  padding: 25px 40px 40px;
  margin: 0 10px;
  background: linear-gradient(171.39deg, #234A83 0.5%, #330E41 99.36%);
  border-radius: 20px;
  min-height: 470px;
}
@media (max-width: 768px) {
  .payment-system .wrapper {
    padding: 25px 20px 40px;
    min-height: 400px;
  }
}
.payment-system__step[data-step="1"] {
  padding: 0 30px;
}
@media (max-width: 768px) {
  .payment-system__step[data-step="1"] {
    padding: 0;
  }
}
.payment-system__step.hidden {
  display: none;
}
.payment-system__step.active {
  display: block;
}
.payment-system__back {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='16' viewBox='0 0 20 16' fill='none' %3E%0A%3Cpath d='M0.588059 7.10222C0.197535 7.49274 0.197535 8.12591 0.588059 8.51643L6.95202 14.8804C7.34254 15.2709 7.97571 15.2709 8.36623 14.8804C8.75676 14.4899 8.75676 13.8567 8.36623 13.4662L2.70938 7.80933L8.36623 2.15247C8.75676 1.76195 8.75676 1.12878 8.36623 0.738258C7.97571 0.347734 7.34254 0.347734 6.95202 0.738258L0.588059 7.10222ZM19.6575 6.80933H1.29517V8.80933H19.6575V6.80933Z' fill='white'/%3E%0A%3C/svg%3E%0A");
  width: 20px;
  height: 16px;
  position: absolute;
  left: 30px;
  top: 30px;
  cursor: pointer;
}
.payment-system-card {
  display: flex;
  justify-content: space-between;
  align-content: center;
}
@media (max-width: 768px) {
  .payment-system-card {
    flex-direction: column;
    gap: 20px;
  }
  .payment-system-card .input {
    width: 100%;
  }
}
.payment-system-amount {
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-top: 20px;
  gap: 20px;
}
@media (max-width: 768px) {
  .payment-system-amount {
    align-items: flex-end;
    flex-direction: column;
    gap: 20px;
  }
  .payment-system-amount .input,
  .payment-system-amount .btn {
    width: 100%;
    min-width: unset;
  }
}
.payment-system__title {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.02em;
}
.payment-system-message {
  font-size: 28px;
  line-height: 33px;
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.02em;
  margin-top: 30px;
  /* margin-top: 70px; */
}
@media (max-width: 768px) {
  .payment-system-message {
    font-size: 25px;
    line-height: 30px;
    margin-top: 50px;
  }
}
.payment-system-message_next {
  /* margin-top: 40px;
  margin-bottom: 70px; */
  margin-bottom: 30px;
  margin-top: 15px;
}
@media (max-width: 768px) {
  .payment-system-message_next {
    margin-top: 30px;
    margin-bottom: 50px;
  }
}
.payment-system-input-text {
  margin-top: 15px;
  width: 280px;
  text-align: center;
}
@media (max-width: 768px) {
  .payment-system-input-text {
    width: 100%;
  }
}
.payment-system__banks {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 40px;
}
.payment-system__info {
  display: flex;
  justify-content: space-between;
  margin: 15px 10px 0 10px !important;
}
@media (max-width: 768px) {
  .payment-system__info {
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }
}
.payment-system__info .logo {
  font: 700 48px/58px "Gilroy", sans-serif;
}
.payment-system__info .contact-us {
  display: flex;
  align-items: center;
  gap: 15px;
  color: #fff;
  text-decoration: none;
}
.payment-system .payment-pay {
  display: flex;
  gap: 40px;
  margin-top: 35px;
}
@media (max-width: 768px) {
  .payment-system .payment-pay {
    flex-direction: column;
  }
}
.payment-system .payment-methods {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
  min-width: 240px;
  height: 300px;
  padding-right: 20px;
  margin-top: 20px;
}
@media (max-width: 768px) {
  .payment-system .payment-methods {
    padding-right: 0;
    overflow: visible;
    height: auto;
  }
}
.payment-system .payment-methods::-webkit-scrollbar {
  width: 3px;
  background-color: #fff;
}
.payment-system .payment-methods::-webkit-scrollbar-thumb {
  background-color: #7F77FF;
}
.payment-system .payment-methods::-webkit-scrollbar-track {
  background-color: #fff;
}
.payment-system .payment-input {
  margin: 20px 0;
}

body {
  margin: 0;
  font: 500 18px/21px "Gilroy", sans-serif;
  color: #fff;
}
body::before {
  content: "";
  background-color: #311e29;
  background-image: linear-gradient(43deg, #311e29 0%, #3c242f 20%, #531c30 54%, #47303d 83%, #343542 100%);
  background-size: cover;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}

