body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body.second::before {
  background-color: #311e29;
  background-image: linear-gradient(
    43deg,
    #311e29 0%,
    #3c242f 20%,
    #531c30 54%,
    #47303d 83%,
    #343542 100%
  );
  background-size: cover;
}
