.payment-system {
  max-width: 820px;
  margin: 100px auto;
  position: relative;

  @media (max-width: 768px) {
    margin: 30px auto;
  }

  .wrapper {
    /* padding: 15px 40px 25px; */
    padding: 25px 40px 40px;
    margin: 0 10px;
    background: linear-gradient(171.39deg, #234A83 0.5%, #330E41 99.36%);
    border-radius: 20px;
    min-height: 470px;

    @media (max-width: 768px) {
      padding: 25px 20px 40px;
      min-height: 400px;
    }
  }

  &__step {
    &[data-step="1"] {
      padding: 0 30px;

      @media (max-width: 768px) {
        padding: 0;
      }
    }

    &.hidden {
      display: none;
    }

    &.active {
      display: block;
    }
  }

  &__back {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='16' viewBox='0 0 20 16' fill='none' %3E%0A%3Cpath d='M0.588059 7.10222C0.197535 7.49274 0.197535 8.12591 0.588059 8.51643L6.95202 14.8804C7.34254 15.2709 7.97571 15.2709 8.36623 14.8804C8.75676 14.4899 8.75676 13.8567 8.36623 13.4662L2.70938 7.80933L8.36623 2.15247C8.75676 1.76195 8.75676 1.12878 8.36623 0.738258C7.97571 0.347734 7.34254 0.347734 6.95202 0.738258L0.588059 7.10222ZM19.6575 6.80933H1.29517V8.80933H19.6575V6.80933Z' fill='white'/%3E%0A%3C/svg%3E%0A");
    width: 20px;
    height: 16px;
    position: absolute;
    left: 30px;
    top: 30px;
    cursor: pointer;
  }

  &-card {
    display: flex;
    justify-content: space-between;
    align-content: center;

    @media (max-width: 768px) {
      // align-items: flex-end;
      flex-direction: column;
      gap: 20px;

      .input {
        width: 100%;
      }
    }
  }

  &-amount {
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin-top: 20px;
    gap: 20px;

    @media (max-width: 768px) {
      align-items: flex-end;
      flex-direction: column;
      gap: 20px;

      .input,
      .btn {
        width: 100%;
        min-width: unset;
      }
    }



  }

  &__title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    text-align: center;
    letter-spacing: -0.02em;
  }

  &-message {
    font-size: 28px;
    line-height: 33px;
    font-weight: 600;
    text-align: center;
    letter-spacing: -0.02em;
    margin-top: 30px;
    /* margin-top: 70px; */

    @media (max-width: 768px) {
      font-size: 25px;
      line-height: 30px;
      margin-top: 50px;
    }

    &_next {
  /* margin-top: 40px;
  margin-bottom: 70px; */
  margin-bottom: 30px;
  margin-top: 15px;

      @media (max-width: 768px) {
        margin-top: 30px;
        margin-bottom: 50px;
      }
    }
  }

  &-input-text {
    margin-top: 15px;
    width: 280px;
    text-align: center;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &__banks {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 40px;
  }


  &__info {
    display: flex;
    justify-content: space-between;
    //margin: 40px 10px 0 10px;
    margin: 15px 10px 0 10px !important;

    @media (max-width: 768px) {
      justify-content: center;
      flex-wrap: wrap;
      gap: 20px;
    }

    .logo {
      font: 700 48px/58px 'Gilroy', sans-serif;
    }

    .contact-us {
      display: flex;
      align-items: center;
      gap: 15px;
      color: #fff;
      text-decoration: none;
    }
  }

  .payment {
    &-pay {
      display: flex;
      gap: 40px;
      margin-top: 35px;

      @media (max-width: 768px) {
        flex-direction: column;
      }
    }

    &-methods {
      display: flex;
      flex-direction: column;
      gap: 20px;
      overflow: auto;
      min-width: 240px;
      height: 300px;
      padding-right: 20px;
      margin-top: 20px;

      @media (max-width: 768px) {
        padding-right: 0;
        overflow: visible;
        height: auto;
      }

      &::-webkit-scrollbar {
        width: 3px;
        background-color: #fff;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #7F77FF;
      }

      &::-webkit-scrollbar-track {
        background-color: #fff;
      }
    }

    &-input {
      margin: 20px 0;
    }
  }
}