.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-between {
  justify-content: space-between;
}

.m-t-25 {
  margin-top: 25px;
}

.m-y-50 {
  margin: 50px 0;
}

.m-y-md-30 {
  @media (max-width: 768px) {
    margin: 30px 0;
  }
}

.gap-40 {
  gap: 40px;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
  gap: 20px;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.flex-2 {
  flex: 2;
}

.flex-4 {
  flex: 4;
}

.op-5 {
  opacity: .5;
}

.text-center {
  text-align: center;
}