.text {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 5px;

  &_sm {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 5px;
  }

  &_md {
    font-size: 24px;
    line-height: 28px;
  }

  &_lg {
    font-size: 32px;
    line-height: 39px;
    font-weight: 700;
  }

  &_xl {
    font-size: 36px;
    line-height: 44px;
    font-weight: 700;
  }

  &-orange {
    color: #FFD361;
  }

  &-danger {
    color: #FF3737;
  }
  
  &-white {
    color: #fff;
  }
}