.btn {
  border: none;
  letter-spacing: -0.02em;
  border-radius: 10px;
  cursor: pointer;
  transition: .2s ease;
  transition-property: background-color, color;
  

  &:focus {
    outline: none;
  }

  &_lg {
    min-width: 320px;
    padding: 30px;
    font: 600 28px/33px 'Gilroy', sans-serif;
  }

  &-white {
    background-color: #fff;

    &:hover {
      background-color: #f0f0f0;
    }
  }

  &-outline {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 25px 30px 25px 25px;
    background-color: #3F3B7B;
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 15px;
    font: 600 20px/24px 'Gilroy', sans-serif;

    &:hover {
      border-color: transparent;
      background-color: #fff;
      color: #000;
    }
  }

  &-wide {
    min-width: 240px;
  }

  &-hover-img-invert {
    img {
      transition: .2s ease filter;
    }

    &:hover {
      img {
        filter: invert(1);
      }
    }
  }

  &-payment {
    background: #3F3B7B;
    color: #fff;
    border-radius: 15px;
    padding: 20px 50px 20px 20px;
    display: flex;
    gap: 20px;
    align-content: center;
    font: 600 20px/24px 'Gilroy', sans-serif;

    &:hover {
      background: #46418d;
    }

    &.active {
      background: #fff;
      color: #3F3B7B;
    }

    span {
      .btn-payment__desc {
        display: block;
        text-align: left;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.02em;
        opacity: .5;
        margin-top: 2px;
      }
    }
  }

  &-payment-success {
    background: rgba(116, 205, 93, 0.8);
    color: #fff;
    border-radius: 10px;
    padding: 15px 40px;
    font: 600 20px/24px 'Gilroy', sans-serif;

    &:hover {
      background: rgba(116, 205, 93, 1);
    }
    
    &:disabled {
      background-color: #1d2129;
      color: rgba(255, 255, 255, 0.3);
      cursor: default;
      box-shadow: 3px 3px 3px rgb(0 0 0 / 32%);
  
      &:hover {
        background-color: #1d2129;
      }
    }
  }
}