*,
*::before,
*::after {
  box-sizing: border-box;
}

@import 'common/index';
@import 'components/index';

body {
  margin: 0;
  font: 500 18px/21px 'Gilroy', sans-serif;
  color: #fff;

  &::before {
    content: '';
    background-color: #311e29;
    background-image: linear-gradient(43deg, #311e29 0%, #3c242f 20%, #531c30 54%, #47303d 83%, #343542 100%);
    background-size: cover;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
  }
}